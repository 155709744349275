import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "building-forward-and-upward",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#building-forward-and-upward",
        "aria-label": "building forward and upward permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Building Forward and Upward`}</h1>
    <p>{`As the Pegasys core development team, we always keep in mind our commitment to the community to continuously improve the protocol and keep bringing value to users. 2022 has been a great year for Pegasys – so far, we have released powerful features such as swap, staking, yield farming, and our native token PSYS, unlocking way more utility to the Syscoin NEVM. Complementary to that, we have been continuously closing in new partnerships to bring extra utility to PSYS, such as lending platforms, stablecoins, oracles and yield aggregators.`}</p>
    <p>{`From now on, our goal is clear: keep making Pegasys better through new and improved functionalities to offer the current users a smoother experience while at the same time growing our community and bringing value to the PSYS token. In parallel, we’re constantly pushing the Syscoin ecosystem to the next level through new infrastructure and providers that bring a ton of fundamentals to the network.`}</p>
    <p>{`It’s also important to reiterate how close we are to the general Syscoin roadmap. The NEVM is just the beginning, so the Syscoin community can expect big news very soon regarding Rollup technology. As the largest project in the network, Pegasys will be leading the way on Syscoin L2s as well, and we expect huge UX improvements by leveraging state-of-the-art scalability solutions alongside the Foundation.`}</p>
    <p>{`After a successful launch in late April, our team kept working hard to put into practice the plan that will make this goal a reality. We are making big moves to grow Pegasys into a true next-generation DEX. Here is what is coming for Pegasys, take notes and make sure to follow through.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b2b4045f2e0dd9fa54ccc080626eb7c3/d2c28/road.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.333333333333336%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAC4jAAAuIwF4pT92AAADA0lEQVQozxXQa09bBQCA4UNvp+3pjfb0fgq9UlmFrhegaxldO2gLlQKyMejQIhvuQkEZsMGSLS6bioxlLsMsxhB1Gr/oMmJMNC7L4gc/+Bv8Ma/x+QmPYJXOYHXW6fQ2kCwlLFINb2SO6NgO7uAE6eQyxeR15sfuMz36OSOF+2QH7pJKPcTn3sHl/Aib7UOM0kXU2ncQrPpBzOYcjq4q7nANk9iHnJmiZ+MR+b4m9fGbFPKbLM7s024dcmP5iMvzX1PKPUFx7uBz38Qub2AwtVDrxhHs+jxWYxrJlsDVW8LaPYggRjBKSU4odWLuBnZ5Eo9vkZjS4q3gB4SUFbyeq1i9lzDJVzF0ttGYW6jEKkK3+RwucwOzfZBoeJpK9hPysTXKvbvk4tepDXzByfQK4eIakeo2gf1npL76ifjj7/A+PMLWfowor6GWmqjEUYSYZRa/+TwWd4VT8S3u1l5zq/KC3dFjVuvPuLP4kp29Q5I/fEv/nT36jn8he/yK1M+/EX/xK94vf8QVuIXO1KRDW0aQ9cP4LU28vhkCifMMnL6M3X2anniVLmWIiUKL9z++h/zNU/yTbRzlLRy1e0jFdZwj26SHD+hyr6M3zqJS5xAMmix2aYpw8CJ92TlO5ucROzNETpSoTlxgduYSC0ubNG4/YbZwm3ORNS5Et8kFlmn6b7Af2iPofg/9/3/C2whG7QRW6QrRUItcfoFATwWtaQidbRyr910S6XWCmVWWop/xV+lv/qi/4VXxDbuhA74Pf8q/XQ+YDm0QUxaxGs4gWPRtzNIKge4G4UQFnakXjTSE1jKKzr6AVr6GYJxizLHJy/yfPB8+5rjwO1uRAw4jR/wTfc1ZpU0i0CLkqCIYxBomWwOHUkVvCqPShlHps3SIWTqMZVTGMQRxCJPuFGnPHOXYErXIFTK+JquBBzwNPqfiuUbM08BpSiE45BmsjgoaXTcarR+1mKBD14+g6UFQBxFUUURjhmT/JIp/BJ+riEcuoshl/I4ybnuJiKtOUM4jCB7+AylrdrDq5UWdAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Pegasys Roadmap",
              "title": "Pegasys Roadmap",
              "src": "/static/b2b4045f2e0dd9fa54ccc080626eb7c3/c1b63/road.png",
              "srcSet": ["/static/b2b4045f2e0dd9fa54ccc080626eb7c3/5a46d/road.png 300w", "/static/b2b4045f2e0dd9fa54ccc080626eb7c3/0a47e/road.png 600w", "/static/b2b4045f2e0dd9fa54ccc080626eb7c3/c1b63/road.png 1200w", "/static/b2b4045f2e0dd9fa54ccc080626eb7c3/d61c2/road.png 1800w", "/static/b2b4045f2e0dd9fa54ccc080626eb7c3/97a96/road.png 2400w", "/static/b2b4045f2e0dd9fa54ccc080626eb7c3/d2c28/road.png 4000w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Pegasys Roadmap`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "q2-2022",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#q2-2022",
        "aria-label": "q2 2022 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`Q2 2022`}</strong></h2>
    <p>{`The second quarter of 2022 has already brought us the release of the PSYS token and liquidity incentives (yield farming and staking). However, we still have two other substantial releases - that our users are looking forward to - planned for Q2:  UI/UX improvements and the launch of a token creator.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`UI/UX improvements:`}</strong>{` It comprises a series of redesign and quality of life upgrades based on user feedback and our own research, including a design revamp to deliver the best possible experience in DeFi. This also moves Pegasys further away from the Uniswap interface and provides a better brand experience and custom feel to our front-end.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Token creator:`}</strong>{` The Token Wizard is an interface to interactively build a smart contract out of components from OpenZeppelin Contracts Library. With this no-code solution, the user can frictionlessly get a tailor-made token ready to be deployed with Metamask. The interface lets the user select the kind of contract (current support for ERC20, ERC721 and ERC1155) and set parameters and desired features like token name, symbol, pre-mint amount, access control, etc. There are actually a good number of blockchain projects that don’t need big, complex smart contracts to initially hit the market such as memecoins, NFT community tokens, real-world tokenizations projects and many other applications. With minimal transaction costs and golden-standard security, the Pegasys Token Creator will pave the way for Syscoin to cater these niches.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Integrations:`}</strong>{` This topic is dependent on external factors and providers, but Pegasys has plans on bringing to the app:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`A token locking suite leveraging Revest’s protocol;`}</li>
          <li parentName="ul">{`Fiat on-ramp.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "q3-2022",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#q3-2022",
        "aria-label": "q3 2022 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`Q3 2022`}</strong></h2>
    <p>{`For the third quarter of 2022, we are aiming to deliver an even bigger update, with milestones that will take our protocol to the next level. In this quarter, you can expect the establishment of the Protocol Governance (an aspect that we know our community is anxiously waiting for), new front-end features to further improve UX and, of course, the widely discussed and hoped-for layer 2 rollup.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Protocol Governance:`}</strong>{`  The Pegasys protocol will be governed by PSYS token holders, using three distinct components: the PSYS token, Governor Alpha, and Timelock. Together, these contracts allow the community to propose, vote and implement changes to the Pegasys protocol. Besides the smart-contract side of things, we will also release a governance portal so PSYS holders can discuss the proposals.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`New front-end features:`}</strong>{` This release will be dedicated to improving the analytics page, so we will be able to provide a superior DEX user experience with one centralized dashboard to track your portfolio and watchlist, and trade all within.`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`This release will also feature: `}<ul parentName="li">
          <li parentName="ul">{`Limit Orders;`}</li>
          <li parentName="ul">{`ZAP: One-click token convert to LP tokens.`}</li>
        </ul></li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Optimistic (Layer 2 Rollup):`}</strong>{` At this point, for anyone who follows the Syscoin ecosystem, it goes without saying that the layer 2 rollup is a very important milestone for the Syscoin NEVM. To keep up to this evolution we are cooperating closely with the Syscoin Foundation so we can move quickly with Pegasys in that regard as well. The last Syscoin roadmap update pointed to Optimistic as a quicker approach to a layer 2 rollup while ZK still has some challenges that need to be addressed. So we adapted to this new reality in order to be the first protocol to explore this new Syscoin phase and bring its benefits to the Pegasys community.`}</li>
    </ul>
    <h2 {...{
      "id": "q4-2022",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#q4-2022",
        "aria-label": "q4 2022 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`Q4 2022`}</strong></h2>
    <p>{`Finally, to end the year on a high note, our team is aiming to further increase the PSYS token utility by integrating a Lending Protocol, which will also mean an important milestone to the Syscoin DeFi ecosystem.`}</p>
    <p>{`The Lending Protocol will be a decentralized money market built on top of Pegasys, where users can participate as depositors or borrowers. It will permit investors to earn a passive income providing liquidity through lending and leveraging through borrows as well as bringing more utility to PSYS token using PLP as collateral.`}</p>
    <h2 {...{
      "id": "2023-preview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2023-preview",
        "aria-label": "2023 preview permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`2023 preview`}</strong></h2>
    <p>{`Naturally, we expect and want the long-term roadmap to be shaped by our community through the governance system. However, we can already have a glimpse of some features and improvements planned for early 2023, which we currently consider to be highly strategic for the future of Pegasys. Among them we can cite Stablecoin AMM, launchpad/liquidity bootstrapper, and also the ZK rollup integration.`}</p>
    <h2 {...{
      "id": "closing-points",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#closing-points",
        "aria-label": "closing points permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`Closing points`}</strong></h2>
    <p>{`Our team is truly looking forward to making this roadmap into reality as fast and well executed as possible, evolving Pegasys to be evermore profitable and beneficial for our community and investors. We appreciate all the support from our community we received throughout this journey so far and aim to continue to deserve it.`}</p>
    <p>{`We would like to highlight that we are always open to feedback and suggestions from our community once we know that the community is the fundamental structure for a successful project.`}</p>
    <p>{`We are building - together - the future of decentralized finance.`}</p>
    <p>{`Stay tuned for everything that is coming!`}</p>
    <br />
This roadmap represents the current view of our priority orders based on what we believe to be the right direction for both the protocol and our community. However, it could have some changes due to external dependencies and unforeseen technical challenges that may appear.
    <p>{`Feel free to head over to our `}<a parentName="p" {...{
        "href": "https://discord.com/invite/UzjWbWWERz"
      }}>{`Discord`}</a>{` server to voice any concerns, questions or recommendations you may have.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      